import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./mediascopie/mediascopie.module').then(m => m.MediascopieModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)], /*{ enableTracing: true }*/
    exports: [RouterModule]
})

export class AppRoutingModule {
}
